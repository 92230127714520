//Liste des applications
export const allapps = [
  {
    id: "4842f0f4-f5ba-4e47-84a9-22eadf17d9c3",
    nom: "Office 365",
    description: "Messagerie, contacts, calendrier",
    isNew: false
  },
  {
    id: "04011420-c1a8-4410-86b2-cb78bbbbae87",
    nom: "Artips",
    description: "Histoire de l'art et de leurs auteurs",
    isNew: false
  },
  {
    id: "2a598427-671f-4a9a-9b8d-23150e77e09e",
    nom: "CrossKnowledge",
    description: "Cours de management et de leadership",
    isNew: false
  },
  {
    id: "f7e3b9ba-cf10-4a05-9c81-054dd37a31af",
    nom: "Cyberlibris",
    description: "Bibliothèque d'ouvrages numériques",
    isNew: false
  },
  {
    id: "4fca7449-0222-46cb-ac24-f55171f962ad",
    nom: "GoFluent",
    description: "Formation à l’anglais professionnel",
    isNew: false
  },
  {
    id: "c015a206-db7e-45c1-9740-cf01b766c94f",
    nom: "Hyperplanning DSI",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "27bd8aa8-9ebb-4770-9f5d-7c2c03755574",
    nom: "Hyperplanning Albertville",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "60b01d50-e871-4dd7-8820-cb2fea02e90a",
    nom: "Hyperplanning Angers",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "a10e494e-78f3-4725-82b8-dcd67f003d7d",
    nom: "Hyperplanning Annecy",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "e0ae3dc3-a2be-492c-b89f-09ef3bf8eead",
    nom: "Hyperplanning Annecy-02",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "25a3f3ff-8ac4-4743-ab0e-d84cb7f9b6c3",
    nom: "Hyperplanning Bordeaux-01",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "37d8fdc8-52a8-40da-aed4-4dccb29cf03b",
    nom: "Hyperplanning Bordeaux-02",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "4ea2f7b4-058f-4e13-a8b6-a254768975ef",
    nom: "Hyperplanning Bruz",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "0dc086a0-a817-487a-9043-2f866ecb38fc",
    nom: "Hyperplanning Caen",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "06eb1e7f-c8ca-4e16-a7ce-f2705664536f",
    nom: "Hyperplanning Chambery",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "2d2ad900-8ba0-48fd-8612-feccbdec1e2a",
    nom: "Hyperplanning Dammarie-les-Lys",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "bbb0482d-31eb-4da1-ab17-6c77ab00d044",
    nom: "Hyperplanning Genève",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "7af69dad-219a-430a-a3bc-8979ea476ce8",
    nom: "Hyperplanning Grenoble",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "c2cc710b-b248-405d-8494-2b5d76218371",
    nom: "Hyperplanning Iscom Bordeaux",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "cce50987-2710-4f40-97cb-511b4eb70ccc",
    nom: "Hyperplanning Iscom Paris",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "2d54bfaa-c29f-4885-8da4-609d2de1e149",
    nom: "Hyperplanning Issy-les-Moulineaux",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "4c23554a-2659-4f7e-b8f9-11c1b82962c8",
    nom: "Hyperplanning Laval",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "d2d0eaaf-d1cb-4aa8-b626-659de3968edc",
    nom: "Hyperplanning Levallois-Perret",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "99046f53-e14d-40fe-ae2e-aa85204b835b",
    nom: "Hyperplanning Lille",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "3d4713f0-7f09-433b-a157-5dac86c663e6",
    nom: "Hyperplanning Lyon-01",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "bb528341-af38-42d4-b43f-fff5cb2ed4da",
    nom: "Hyperplanning Lyon-02",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "e0ae8943-1195-4c0a-a79e-a2608c9ac0cd",
    nom: "Hyperplanning Lyon-03",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "1f5859d7-8053-4a4e-88f6-c5bf865f908b",
    nom: "Hyperplanning Marseille",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "66659523-f3c1-4a2c-a398-b86a3791506b",
    nom: "Hyperplanning Montpellier",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "86693cd9-9398-49eb-bf6b-d2f0b3804d8c",
    nom: "Hyperplanning Nancy",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "52166dd0-58bc-4414-8609-5788248efefb",
    nom: "Hyperplanning Nantes",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "25b7b7f0-6e7d-4b30-89b7-e870d1382d42",
    nom: "Hyperplanning Nice",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "3d460ce9-d333-42d1-af82-541886f992ba",
    nom: "Hyperplanning Paris-01",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "628aef8f-d0d4-425e-9880-98bc81a73be3",
    nom: "Hyperplanning Paris-02",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "166fc954-6e61-47bc-b924-0e93c92740e6",
    nom: "Hyperplanning Paris-03",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "8ca7edc7-052f-4896-a3db-bdb57c9f8559",
    nom: "Hyperplanning Reims",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "b8b4667a-fac2-4167-bc45-130d78470120",
    nom: "Hyperplanning Rennes",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "b57b7b56-9e86-4c93-bd41-6d2edb695baa",
    nom: "Hyperplanning Saint-Quentin",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "c13019b4-55fa-4053-bc19-776ec5cf984e",
    nom: "Hyperplanning Strasbourg",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "f7596bbb-6d91-460f-b1bd-f5177d593e99",
    nom: "Hyperplanning Toulouse-01",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "354c9731-1a48-49fc-b861-3c10b6b03439",
    nom: "Hyperplanning Toulouse-02",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "3083c469-2890-40e2-b382-f4597ac8d402",
    nom: "Hyperplanning Tours",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "8f5d5601-cb5f-4e01-877c-c0ab72a3fd44",
    nom: "Hyperplanning Vannes",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "844029b1-1191-4b28-aabf-b737e0354d50",
    nom: "Hyperplanning Ville-la-grand",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "3f804f1f-8971-420c-9cd9-f7b32e7200b9",
    nom: "Hyperplanning Vitre",
    description: "Planning des cours",
    isNew: false
  },
  // ------------------------------------------------------
  // Retiré le 27/07/2023
  // ------------------------------------------------------
  // {
  //   id: "f60de804-1c14-42f7-b3be-4e090b421ea9",
  //   nom: "Azure Dev Tools",
  //   description: "Téléchargement de logiciels Microsoft",
  //   isNew: false
  // },
  {
    id: "cedf1b7d-b2d8-4954-8353-0f7a9e4e26a9",
    nom: "MyDigitalUniversity",
    description: "Learning Management System",
    isNew: false
  },
  {
    id: "27379fb0-0d0f-4a22-891d-74b7c6c37886",
    nom: "Orthodidacte",
    description: "Cours d'orthographe en ligne",
    isNew: false
  },
  {
    id: "dd4c216c-48b1-4b3a-b9f7-411f963b55ec",
    nom: "Projet Voltaire",
    description: "Cours d'orthographe en ligne",
    isNew: false
  },
  {
    id: "460043b2-f5fa-4f79-a506-c693d873fefb",
    nom: "Theia",
    description: "Plateforme de formation et d'évaluation",
    isNew: false
  },
  {
    id: "70872f7f-35df-4dc9-b297-36fdb7de7daa",
    nom: "Rosetta Stone Catalyst",
    description: "Cours de langues étrangères",
    isNew: false
  },
  {
    id: "3bb02be5-50ad-4ee5-ae35-25de62604d32",
    nom: "Campus i-Manuel",
    description: "Solution d'I-Manuel pour les BTS",
    isNew: true
  },
  {
    id: "2845d930-8705-4ccb-ae1c-251e4e53af17",
    nom: "Percipio",
    description: "Outil d'apprentissage pour des solutions digitales",
    isNew: true
  },
  {
    id: "1db19fdb-8fb3-4916-9fbe-dfd17591f44b",
    nom: "JobTeaser ISCOM",
    description: "Solution de recrutement",
    isNew: true
  },
  {
    id: "f466e896-a4da-4697-84c8-64b538023d4d",
    nom: "JobTeaser MBway",
    description: "Solution de recrutement",
    isNew: true
  },
  {
    id: "94f287e7-fee3-448a-a512-1906b1892860",
    nom: "Wooclap",
    description: "Support de cours en ligne",
    isNew: true
  },
  {
    id: "0f3f802f-876e-4c29-aa41-61eb34fcc250",
    nom: "WooFlash",
    description: "Support de cours en ligne",
    isNew: true
  },
  {
    id: "9dfe439d-f886-4688-8eb6-204818314bd6",
    nom: "3W Academy",
    description: "Plateforme d'apprentissage WEB en ligne",
    isNew: true
  },
  {
    id: "6af6edb0-1dd7-431c-ae37-583f9cdafe1a",
    nom: "Global Exam",
    description: "Certification de langues en ligne",
    isNew: true
  },
  {
    id: "49824f15-b458-460f-a0e7-69f24ba8b723",
    nom: "Hyperplanning ACFA Montpellier",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "3fa48725-0756-49d9-a4e5-4c6c191f8b34",
    nom: "Hyperplanning Studio M Montpellier",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "572a5ae2-27dd-4e42-a869-a102f9e4e68c",
    nom: "Hyperplanning Cime Art Beziers",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "00ce34a0-4493-4eec-ba2c-2dcf5a24a7bd",
    nom: "Hyperplanning Studio M Lyon",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "9ec10147-4fbc-4f44-a2e5-a5f40cd8451e",
    nom: "Hyperplanning Studio M Paris",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "982d06e5-ce5a-4fea-ade7-599a5065fc92",
    nom: "Hyperplanning Studio M Toulouse",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "e5a85da6-6171-4c02-954c-ffbf1b7a23b6",
    nom: "Hyperplanning Supcrea Grenoble",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "b8024990-1e52-43db-8f4b-c37c7fac4634",
    nom: "Linkedin Learning",
    description: "Plateforme d'apprentissage en ligne",
    isNew: true
  },
  {
    id: "da96a4d2-fd81-447e-941d-60dac43e2ee5",
    nom: "HPP MBway Lyon",
    description: "Planning des cours",
    isNew: false
  },
  {
    id: "4d3ae66b-0b48-4143-b962-bedcc2c507d4",
    nom: "Pix",
    description: "Évaluation des compétences numériques",
    isNew: true
  },
  {
    id: "b6e88c98-c8db-4138-a998-e08d3f07ebef",
    nom: "Turnitin",
    description: "Dépôt travaux numériques et détection de fraude",
    isNew: true
  },
  {
    id: "05e6fe0c-6a09-4674-80d7-e7b2936eb427",
    nom: "Let's Learn English",
    description: "Apprentissage de l'Anglais",
    isNew: true
  },
  {
    id: "590a8289-4ae0-4194-9e6e-6fe44dc7226f",
    nom: "Cafeyn",
    description: "Kiosque de presse numérique",
    isNew: true
  },
  {
    id:"9110da50-6755-449f-8107-9f9fc8c56ccb",
    nom: "SEIRA AFTEC",
    description: "Seira Aftec",
    isNew: true
  },
  {
    id: "77deb8bd-7471-4e2d-a335-ca85d65546b5",
    nom: "SEIRA IPAC BACHELOR FACTORY",
    description: "Seira Ipac Bachelor Factory",
    isNew: true
  },
  {
    id: "bde89e70-5142-4b17-a801-dceb0732d9d8",
    nom: "SEIRA IHECF",
    description: "Seira Ihecf",
    isNew: true
  },
  {
    id: "f3fd8198-ddee-427c-af2a-e655ea647590", 
    nom: "SEIRA IPAC-ONLINE",
    description: "Seira Ipac-Online",
    isNew: true
  },
  {
    id: "352250c4-8b34-4e56-8132-b703ddb49562",
    nom: "SEIRA ISCOM",
    description: "Seira Iscom",
    isNew: true
  },
  {
    id: "e3dafe96-b554-4e65-a424-84f00e72e87d",
    nom: "SEIRA MBWAY",
    description: "Seira Mbway",
    isNew: true
  },
  {
    id: "71a6832d-3870-4adf-936c-21b90acc8c37",
    nom: "SEIRA MYDIGITALSCHOOL",
    description: "Seira Mydigitalschool",
    isNew: true
  },
  {
    id: "665f02a4-a5a2-4745-a0e2-b6e6ed6e54a3",
    nom: "SEIRA PIGIER",
    description: "Seira Pigier",
    isNew: true
  },
  {
    id: "ab6ecf60-218c-456f-9c52-c65d23d41e1e",
    nom: "SEIRA SCIM",
    description: "Seira Scim",
    isNew: true
  },
  {
    id: "deae9f67-9b5b-439b-bc3d-5e90bb54b859",
    nom: "SEIRA STUDIO M",
    description: "Seira Studio M",
    isNew: true
  },
  {
    id: "35e14f22-1a38-4c03-b164-f00b82940973",
    nom: "SEIRA TUNON",
    description: "Seira Tunon",
    isNew: true
  },
  {
    id: "2a95762d-5790-4faa-a1dc-1deb5b028c24",
    nom: "SEIRA WIN SPORT SCHOOL",
    description: "Seira Win Sport School",
    isNew: true
  },
  {
    id: "a0b692ed-e035-46e0-ae55-ce0acbac1a18",
    nom: "AIMAIRA Intervenants",
    description: "Intranet AIMAIRA Intervenants",
    isNew: true
  },
  {
    id: "95d1d4c4-483d-4904-bec0-a07691d0cda5",
    nom: "AIMAIRA IHECF",
    description: "Intranet AIMAIRA IHECF",
    isNew: true
  },
  {
    id: "96d68c8e-553a-4422-bd8f-3a70004a6304",
    nom: "AIMAIRA IPAC",
    description: "Intranet AIMAIRA IPAC",
    isNew: true
  },
  {
    id: "61dfc59d-f206-4b21-8540-2d732d4c75d4",
    nom: "AIMAIRA ISCOM",
    description: "IntranetAIMAIRA ISCOM",
    isNew: true
  },
  {
    id: "6f5e9a4f-6d6d-4dc1-a2a4-ff9b1e566b58",
    nom: "AIMAIRA ISIFA",
    description: "Intranet AIMAIRA ISIFA",
    isNew: true
  },
  {
    id: "2c2459bd-0150-48cc-96cb-67ce4b60379d",
    nom: "AIMAIRA WIN",
    description: "Intranet AIMAIRA WIN",
    isNew: true
  },
  {
    id: "62ca8db8-5cd4-4180-bea6-434621f59b37",
    nom: "AIMAIRA MBWAY",
    description: "Intranet AIMAIRA MBWAY",
    isNew: true
  },
  {
    id: "5636b3d5-0102-4517-84fe-8061266f57e9",
    nom: "Bienvenue Formation Apprenant",
    description: "Lien téléchargement application mobile",
    isNew: true
  },
  {
    id: "bf7a1213-6181-4282-9202-8bbc0c6bf196",
    nom: "Bienvenue Formation Intervenant",
    description: "Emargement Numérique",
    isNew: true
  },
  {
    id: "54e3c166-704f-4ee3-a102-618b1de5f055",
    nom: "EduSign",
    description: "Emargement Numérique",
    isNew: true
  },
  {
    id: "2bf30856-f930-4ed1-89ad-6213c532397b",
    nom: "Adobe Express",
    description: "Outil de conception graphique",
    isNew: true
  },  
  {
    id: "89c769fe-ad5b-4684-9029-98d3d32a01ad",
    nom: "Adobe CC Licence Attributeur",
    description: "Demander la licence Adobe Creative Cloud",
    isNew: true
  },
  {
    id: "786b9c69-4ae3-4486-88bb-c8580dc80876",
    nom: "Adobe Creative Cloud",
    description: "Téléchargement de la suite Adobe CC",
    isNew: false
  },
  {
    id: "afac00ac-7b86-4dbf-b22d-0771152c7f25",
    nom: "Rising Up",
    description: "Evaluation et développement des soft skills",
    isNew: true
  },  
  {
    id: "87eead1a-1f76-4bb4-bd69-30cc87c7146e",
    nom: "SEIRA ESPL",
    description: "Seira Espl",
    isNew: true
  },
  {
    id: "8b362010-a54d-41c1-8200-5f032e525fe1",
    nom: "SEIRA IPAC",
    description: "Seira Ipac",
    isNew: true
  },
  {
    id: "7d0e9fe7-4883-4c34-9a2c-224a23ac16c9",
    nom: "SEIRA ESICAD",
    description: "Seira Esicad",
    isNew: true
  },
  {
    id: "0749eea1-4745-4df7-8a9f-00270ddf6f46",
    nom: "Edulea",
    description: "Edulea",
    isNew: false
  }
];
